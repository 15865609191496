import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/index';
import SignInPage from "./pages/signin";

function App() {

  return (
      <Router>
          <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/premium' component={SignInPage} exact />
          </Switch>
      </Router>
  )
}

export default App
